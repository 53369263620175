import { forwardRef } from "react";

import { Toolbar } from "@mui/material";

import useStyles from "./MainHeaderAppBarToolbar.styles";

import type { ToolbarProps } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";

export type CustomMainHeaderAppBarToolbarProps = {};

export type MainHeaderAppBarToolbarProps = Omit<
  ToolbarProps,
  keyof CustomMainHeaderAppBarToolbarProps
> &
  CustomMainHeaderAppBarToolbarProps;

interface MainHeaderAppBarToolbarTypeMap<
  P = {},
  D extends React.ElementType = "div"
> {
  props: P & MainHeaderAppBarToolbarProps;
  defaultComponent: D;
}

type MainHeaderAppBarToolbarComponent =
  OverridableComponent<MainHeaderAppBarToolbarTypeMap>;

const MainHeaderAppBarToolbar: MainHeaderAppBarToolbarComponent = forwardRef(
  (props: MainHeaderAppBarToolbarProps, ref: React.ForwardedRef<any>) => {
    const { classes: muiClasses, sx, ...rest } = props;

    const { classes, theme, cx, css } = useStyles();

    return (
      <Toolbar
        ref={ref}
        {...rest}
        classes={{
          ...muiClasses,
          root: cx(
            classes.root,
            muiClasses?.root,
            sx && css(theme.unstable_sx(sx) as any)
          ),
        }}
      />
    );
  }
);

export default MainHeaderAppBarToolbar;
