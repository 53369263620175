import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<void, "disabled">({
  name: "MainFooter",
  uniqId: "T7bGB7",
})((theme, _, classes) => {
  return {
    root: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },
    logoImg: {
      height: 44,
      width: "auto",
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        height: 38,
      },
    },
    content: {
      padding: theme.spacing(3.75 * 2, 0),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(3.75, 0),
      },
    },
    navItemTitle: {
      textTransform: "uppercase",
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(3.75),
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2.5),
      },
    },
    navItemContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
    },
    allRightsReserved: {
      padding: theme.spacing(0.5, 0),
      backgroundColor: theme.palette.red[100],
    },
    instagramButton: {
      background: theme.palette.socialMedia.instagram,
      "&:hover": {
        background: theme.palette.socialMedia.darkInstagram,
      },
      [`&.${classes.disabled}`]: {
        background: theme.palette.action.disabled,
      },
    },
    contactGridItem: {
      order: 1,
    },
    contact: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(5),
      [theme.breakpoints.down("md")]: {
        gap: theme.spacing(3.75),
      },
      [theme.breakpoints.down("sm")]: {
        gap: theme.spacing(2.5),
      },
    },
    contactInfo: {
      order: 1,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
      [theme.breakpoints.down("md")]: {
        flexDirection: "row",
      },
    },
    contactInfoItem: {
      flex: 1,
      display: "flex",
      gap: theme.spacing(1.25),
    },
    contactSocialMedia: {
      order: 2,
      display: "flex",
      flexWrap: "wrap",
      gap: theme.spacing(2.5),
      [theme.breakpoints.down("md")]: {
        order: 0,
      },
    },
    disabled: {},
  };
});

export default useStyles;
