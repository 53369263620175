import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "MainSubscribe",
})((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 240,
      paddingBottom: 120,
      position: "relative",
      [theme.breakpoints.down("md")]: {
        paddingTop: 120,
        paddingBottom: theme.spacing(3.75),
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: 80,
      },
    },
    content: {
      position: "relative",
      zIndex: 1,
    },
  };
});

export default useStyles;
