import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "NavMenu",
})((theme) => {
  return {
    root: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      overflow: "clip",
    },
    tabs: {
      minHeight: 48 - 4,
      marginLeft: theme.spacing(3.75 / 2),
      marginRight: theme.spacing(3.75 / 2),
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    tab: {
      minHeight: 48 - 4,
      minWidth: "auto",
      padding: theme.spacing(1.5, 3.75 / 2),
      flexShrink: 1,
    },
  };
});

export default useStyles;
