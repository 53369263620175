import AppTab from "@/components/AppTab";
import AppTabs from "@/components/AppTabs";
import AppTypography from "@/components/AppTypography";
import AppLink from "@/components/AppLink";

import { useContext, useMemo } from "react";
import { useRouter } from "next/router";

import MainHeaderContext from "@/layouts/MainLayout/components/MainHeader/MainHeader.context";

import useStyles from "./NavMenu.styles";

const NavMenu = () => {
  const { navMenus } = useContext(MainHeaderContext);

  const { classes } = useStyles();

  const router = useRouter();

  const tabsValue = useMemo(() => {
    const navMenuIndex = navMenus.findIndex((navMenus) =>
      navMenus.pathnameReg.test(router.asPath)
    );
    return navMenuIndex > -1 ? navMenuIndex : false;
  }, [router.asPath, navMenus]);

  return (
    <div className={classes.root}>
      <AppTabs variant="standard" className={classes.tabs} value={tabsValue}>
        {navMenus.map((navMenu, navMenuIndex) => (
          <AppTab
            key={`index-${navMenuIndex}`}
            value={navMenuIndex}
            className={classes.tab}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disabled={navMenu.loading}
            label={
              <AppTypography
                maxWidth="100%"
                variant="inherit"
                component={"span"}
                noWrap
              >
                {navMenu.title}
              </AppTypography>
            }
            title={navMenu.title}
            component={AppLink}
            href={navMenu.href}
            underline="none"
          />
        ))}
      </AppTabs>
    </div>
  );
};

export default NavMenu;
