import axios from "axios";
import { bindActionCreators } from "redux";

import { storeCommonAction, storeServiceAction } from "@/store";
import { commonConfig } from "@/utils/config";

import { useAppDispatch } from "@/hooks";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import type { CancelTokenSource } from "axios";

const AppInitialize = () => {
  const { i18n } = useTranslation();

  const fetchServicesSourceRef = useRef<CancelTokenSource | null>(null);
  const fetchSocialContactSourceRef = useRef<CancelTokenSource | null>(null);

  const dispatch = useAppDispatch();

  const $s_serviceAction = useMemo(
    () => bindActionCreators(storeServiceAction, dispatch),
    [dispatch]
  );

  const $s_commonAction = useMemo(
    () => bindActionCreators(storeCommonAction, dispatch),
    [dispatch]
  );

  const fetchServices = () => {
    fetchServicesSourceRef.current = axios.CancelToken.source();
    $s_serviceAction.fetchServicesSaga({
      cancelToken: fetchServicesSourceRef.current.token,
    });
  };

  const fetchSocialContact = () => {
    fetchSocialContactSourceRef.current = axios.CancelToken.source();
    $s_commonAction.fetchSocialContactSaga({
      cancelToken: fetchSocialContactSourceRef.current.token,
    });
  };

  useEffect(() => {
    fetchServices();
    fetchSocialContact();
    return () => {
      fetchServicesSourceRef.current?.cancel();
      fetchSocialContactSourceRef.current?.cancel();
    };
  }, [i18n.language]);

  useEffect(() => {
    console.log(commonConfig.APP_VERSION);
  }, []);

  return null;
};

export default AppInitialize;
