import { NoSsr } from "@mui/material";
import MainHeader from "@/layouts/MainLayout/components/MainHeader";
import MainFooter from "@/layouts/MainLayout/components/MainFooter";
import MainSubscribe from "@/layouts/MainLayout/components/MainSubscribe";
import AppContainer from "@/components/AppContainer";

import BgCoverSvg from "@@/public/images/svgs/footer-cover.svg";
import BgCoverMobileSvg from "@@/public/images/svgs/footer-cover-mobile.svg";

import useStyles from "./MainLayout.styles";

type MainLayoutProps = {
  children: React.ReactNode;
};

const MainLayout = (props: MainLayoutProps) => {
  const { children } = props;

  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <NoSsr>
        <MainHeader />
      </NoSsr>
      <main className={classes.main}>{children}</main>
      <NoSsr>
        <footer className={classes.mainBottomContainer}>
          <AppContainer className={classes.bgCover}>
            <BgCoverSvg className={classes.bgCoverImg} />
            <BgCoverMobileSvg className={classes.bgCoverMobileImg} />
          </AppContainer>
          <MainSubscribe />
          <MainFooter />
        </footer>
      </NoSsr>
      {/* <NoSsr>
        {!disabledMainFooter && <MainFooter />}
        <MainBottomAppBar
          disabledAppBar={disabledMainBottomAppBar}
          disabledAppBarToolbar={disabledMainBottomAppBarToolbar}
        />
        {!disabledFloatActions && (
          <FloatActions disabledSellIconButton={disabledSellIconButton} />
        )}
      </NoSsr> */}
    </div>
  );
};

export default MainLayout;
