import { darken } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<void, "selected" | "focusVisible">({
  name: "AppListItem",
  uniqId: "qWpSuI",
})((theme, _, classes) => {
  return {
    root: {
      minHeight: 44,
      [`&.${classes.selected}`]: {
        backgroundColor: theme.palette.neutral[200],
        [`&.${classes.focusVisible}`]: {
          backgroundColor: darken(
            theme.palette.neutral[200],
            theme.palette.action.hoverOpacity
          ),
        },
      },
    },
    padding: {
      padding: theme.spacing(1.25),
    },
    selected: {},
    focusVisible: {},
  };
});

export default useStyles;
