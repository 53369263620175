import * as yup from "yup";
import axios from "axios";
import { bindActionCreators } from "redux";

import { axiosHelpers, formikHelpers, reduxHelpers } from "@/utils/helpers";
import { storeCommonAction } from "@/store";
import { alertDialogService, loadingScreenOverlayService } from "@/services";

import { Box } from "@mui/material";
import { FastField, Form, Formik, useFormikContext } from "formik";
import AppContainer from "@/components/AppContainer";
import AppTypography from "@/components/AppTypography";
import AppTextField from "@/components/AppTextField";
import AppSvgIcon from "@/components/AppSvgIcon";
import AppInputAdornment from "@/components/AppInputAdornment";
import AppButton from "@/components/AppButton";
import AlertDialogContentStatus from "@/components/AlertDialogContentStatus";

import EmailIcon from "@@/public/images/icons/email.svg";
import ArrowRightAltIcon from "@@/public/images/icons/arrow-right-alt.svg";

import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "next-i18next";
import { useAppDispatch } from "@/hooks";

import useStyles from "./MainSubscribe.styles";

import type { FastFieldProps, FormikProps } from "formik";
import type { CancelTokenSource } from "axios";

type SubscribeFormValues = {
  email: string;
};

const SubmitButton = () => {
  const { handleSubmit, validateForm, values } =
    useFormikContext<SubscribeFormValues>();

  const { t } = useTranslation();

  return (
    <AppButton
      variant="contained"
      type="submit"
      color="secondary"
      endIcon={<AppSvgIcon component={ArrowRightAltIcon} fontSize="inherit" />}
      onClick={formikHelpers.handleValidateAndSubmit({
        handleSubmit,
        validateForm,
        values,
      })}
    >
      {t("subscribe")}
    </AppButton>
  );
};

const SimpleForm = () => {
  const { classes } = useStyles();

  const { t } = useTranslation();

  return (
    <Form className={classes.root}>
      <AppContainer className={classes.content} maxWidth="md">
        <AppTypography
          variant="headBold48"
          color="secondary"
          align="center"
          mb={2.5}
        >
          {t("mainSubscribeTitle_text")}
        </AppTypography>
        <AppTypography color="secondary" align="center" mb={3.75}>
          {t("mainSubscribeCaption_text")}
        </AppTypography>
      </AppContainer>
      <AppContainer className={classes.content} maxWidth="sm">
        <FastField name="email">
          {({ field, form, meta }: FastFieldProps) => (
            <AppTextField
              fullWidth
              placeholder={t("enterYourEmailEllipsis")}
              bgColor="background.default"
              startAdornment={
                <AppInputAdornment position="start">
                  <AppSvgIcon component={EmailIcon} />
                </AppInputAdornment>
              }
              error={
                !!formikHelpers.showFieldError({
                  form,
                  meta,
                })
              }
              helperText={formikHelpers.showFieldError({
                form,
                meta,
              })}
              {...field}
            />
          )}
        </FastField>
        <Box display="flex" justifyContent="center" mt={3.75}>
          <SubmitButton />
        </Box>
      </AppContainer>
    </Form>
  );
};

const MainSubscribe = () => {
  const formikRef = useRef<FormikProps<SubscribeFormValues> | null>(null);
  const subscribeAppSourceRef = useRef<CancelTokenSource | null>(null);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const $s_commonAction = useMemo(
    () => bindActionCreators(storeCommonAction, dispatch),
    [dispatch]
  );

  const initialValues: SubscribeFormValues = {
    email: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(t("emailIsRequired"))
      .email(t("emailIsInvalid")),
  });

  const handleFormSubmit = async (values: SubscribeFormValues) => {
    subscribeAppSourceRef.current = axios.CancelToken.source();
    loadingScreenOverlayService.fire(`${t("submitting")}...`);
    const response = await reduxHelpers.callActionWithPromise(
      $s_commonAction.subscribeAppSaga,
      {
        params: {
          email: values.email,
        },
        cancelToken: subscribeAppSourceRef.current.token,
      }
    );
    if (response.isCancelled) {
      loadingScreenOverlayService.close();
      return;
    }
    if (axiosHelpers.checkRequestSuccess(response)) {
      alertDialogService.fire({
        content: (
          <AlertDialogContentStatus
            variant="succeeded"
            title={t("thankYouForSubscribing")}
            content={t("youHaveSuccessfullySubscribeToJpStay")}
          />
        ),
        cancelButtonProps: {
          show: false,
        },
      });
      formikRef.current?.resetForm({
        values: initialValues,
        touched: {},
        errors: {},
        submitCount: 0,
      });
    } else {
      alertDialogService.fire({
        content: (
          <AlertDialogContentStatus
            variant="failed"
            title={t("errorExclamation")}
            content={response.message}
          />
        ),
        cancelButtonProps: {
          show: false,
        },
      });
    }
    loadingScreenOverlayService.close();
  };

  useEffect(() => {
    return () => {
      subscribeAppSourceRef.current?.cancel();
    };
  }, []);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      <SimpleForm />
    </Formik>
  );
};

export default MainSubscribe;
